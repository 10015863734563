import { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import useSWR from "swr";
import { useTranslations } from "next-intl";
import { isNumber } from "lodash";

import {
  useEventListener,
  useRandomDoodle,
  useCentraToken,
  voyadoCart,
  getVoyadoItems as getVoyadoCartItems,
} from "../lib/utils";
import { centraGet, centraGetProduct, centraPost } from "../lib/centra-api";
import Link from "./Link";
import CentraProductImage from "./CentraProductImage";
import CentraProductLink from "./CentraProductLink";
import CartItem from "./CartItem";

type VoucherFormStatus = "" | "submitting" | "error" | "notfound" | "success";

type Props = {
  context?: "sidebar" | "checkout";
  onSetGiftWrap?(value: boolean): void;
  currency: string;
};

export default function Cart({
  context = "sidebar",
  onSetGiftWrap,
  currency,
}: Props) {
  const [token] = useCentraToken();
  const doodle = useRandomDoodle();
  const router = useRouter();
  const t = useTranslations("Cart");
  const tProduct = useTranslations("ProductPage");
  const cartRef = useRef<HTMLDivElement>();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [isAdjusting, setIsAdjusting] = useState(false);
  const [giftWrap, setGiftWrap] = useState(false);
  const [activeInput, setActiveInput] = useState("");
  const [newlyAddedId, setNewlyAddedId] = useState("");
  const [addVoucherEnabled, setAddVoucherEnabled] = useState(false);
  const [voucherFormStatus, setVoucherFormStatus] =
    useState<VoucherFormStatus>("");

  const { data, error, mutate } = useSWR(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`,
    (uri) => centraGet(uri, token)
  );

  const isLoading = !data && !error;
  let hasGiftCertificate = false;

  if (data?.selection?.items.length) {
    hasGiftCertificate =
      data?.selection.items[0].brandName === "Gift certificate";
  }

  useEffect(() => {
    onSetGiftWrap?.(giftWrap);
  }, [giftWrap]);

  useEffect(() => {
    setShowCart(false);
  }, [router.asPath]);

  useEffect(() => {
    if (data?.selection.currency !== currency && currency === "EUR") {
      fetch("/api/change-currency", {
        method: "POST",
        headers: { "content-type": "application/json; charset=UTF-8" },
        body: JSON.stringify({ currency: "EUR", token }),
        redirect: "follow",
      })
        .then((res) => {
          // setCurrency("SEK");
          return res.json();
        })
        .then((result) => {
          // mutate(selectionUrl, result.data, false);
        });
    }
    // if (data?.selection.currency) {
    //   if (isCheckoutPage) {
    //     if (data?.selection.currency !== "SEK") {
    //       localStorage.setItem(
    //         "artilleriet_currency",
    //         data?.selection.currency
    //       );
    //       localStorage.setItem(
    //         "selection_eur",
    //         JSON.stringify(data?.selection)
    //       );

    //       fetch("/api/change-currency", {
    //         method: "POST",
    //         headers: { "content-type": "application/json; charset=UTF-8" },
    //         body: JSON.stringify({ currency: "SEK", token }),
    //         redirect: "follow",
    //       })
    //         .then((res) => {
    //           setCurrency("SEK");
    //           return res.json();
    //         })
    //         .then((result) => {
    //           mutate(selectionUrl, result.data, false);
    //         });
    //     } else {
    //       localStorage.removeItem("selection_eur");
    //     }
    //   } else {
    //     if (data?.selection.currency === "EUR") {
    //       localStorage.removeItem("selection_eur");
    //     }

    //     if (localStorage.getItem("artilleriet_currency")) {
    //       fetch("/api/change-currency", {
    //         method: "POST",
    //         headers: { "content-type": "application/json; charset=UTF-8" },
    //         body: JSON.stringify({
    //           country: localStorage.getItem("artilleriet_currency"),
    //           token,
    //         }),
    //         redirect: "follow",
    //       })
    //         .then((res) => {
    //           setCurrency(localStorage.getItem("artilleriet_currency"));
    //           return res.json();
    //         })
    //         .then((result) => {
    //           mutate(selectionUrl, result.data, false);
    //         });
    //     } else {
    //       setCurrency(data?.selection.currency);
    //     }
    //   }
    // }
  }, [currency]);

  useEventListener(
    "artilleriet.togglecart",
    () => {
      setShowCart(!showCart);
    },
    [showCart]
  );

  useEventListener("artilleriet.addedtocart", (event) => {
    setNewlyAddedId(event.detail.item.item);
    setShowCart(true);
  });

  useEffect(() => {
    setIsTransitioning(true);
  }, [showCart]);

  useEffect(() => {
    if (!cartRef.current) {
      return;
    }

    function onTransitionEnd() {
      setIsTransitioning(false);
    }

    const cartElement = cartRef.current;
    cartElement.addEventListener("transitionend", onTransitionEnd);

    return () => {
      cartElement.removeEventListener("transitionend", onTransitionEnd);
    };
  });

  const setItemQuantity = useCallback(async (item, quantity = null) => {
    setIsAdjusting(true);

    const res = await fetch(
      `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/lines/${item.line}`,
      {
        method: quantity > item.quantity ? "POST" : "DELETE",
        headers: {
          accept: `*/*; api-token=${token || "none"}`,
          "content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          quantity: Math.abs(quantity - item.quantity),
        }),
      }
    );

    setIsAdjusting(false);
    const data = await res.json();

    voyadoCart(token, router.locale, getVoyadoCartItems(data));

    mutate(data, false);
  }, []);

  const submitApplyVoucher = useCallback(async (event) => {
    setVoucherFormStatus("submitting");
    const data = new FormData(event.target);
    const voucher = data.get("voucher");

    try {
      const data = await centraPost(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/vouchers`,
        token,
        { voucher }
      );

      mutate(data, false);
      setVoucherFormStatus("success");
      event.target.reset();
    } catch (error) {
      const status = error.message === "404" ? "notfound" : "error";
      setVoucherFormStatus(status);
    }
  }, []);

  const removeVoucher = useCallback(async (voucher) => {
    setIsAdjusting(true);

    const res = await fetch(
      `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/vouchers/${voucher}`,
      {
        method: "DELETE",
        headers: {
          accept: `*/*; api-token=${token || "none"}`,
        },
      }
    );

    setIsAdjusting(false);
    const data = await res.json();
    mutate(data, false);
  }, []);

  useEffect(() => {
    if (addVoucherEnabled) {
      const input = document.querySelector(
        "input[name=voucher]"
      ) as HTMLInputElement;

      input?.focus();
    }
  }, [addVoucherEnabled]);

  useEffect(() => {
    if (!voucherFormStatus) {
      return;
    }

    const timeout = setTimeout(() => {
      setVoucherFormStatus("");
    }, 10 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [voucherFormStatus]);

  useEffect(() => {
    if (!cartRef.current) {
      return;
    }

    if (!showCart) {
      const onTransitionEnd = () => {
        setNewlyAddedId("");
      };

      const eventTarget = cartRef.current;

      eventTarget.addEventListener("transitionend", onTransitionEnd, {
        once: true,
      });

      return () => {
        eventTarget.removeEventListener("transitionend", onTransitionEnd);
      };
    }
  }, [showCart]);

  let voucherFormResponse;

  if (voucherFormStatus === "success") {
    voucherFormResponse = t("vouchers.success");
  } else if (voucherFormStatus === "notfound") {
    voucherFormResponse = t("vouchers.notFound");
  } else if (voucherFormStatus === "error") {
    voucherFormResponse = t("vouchers.error");
  }

  const newlyAdded = newlyAddedId
    ? data?.selection.items.find((i) => i.item === newlyAddedId)
    : null;
  const cartItems = data?.selection.items.filter(
    (i) => i.item !== newlyAddedId
  );

  const { data: newlyAddedData } = useSWR(
    newlyAdded
      ? `centraproduct://${newlyAdded.product.product}/${router.locale}/${currency}`
      : null,
    (uri) => centraGetProduct(uri, token)
  );
  const newlyAddedProduct = newlyAddedData?.product;

  const productHasLimitedStock =
    newlyAddedProduct?.delivery === "" || // legacy attribute
    newlyAddedProduct?.delivery === "Empty" ||
    newlyAddedProduct?.delivery === "Sold Out";

  let newlyAddedDeliveryLabel = "";

  const insideCart: number =
    data?.selection?.items?.find((p) => newlyAddedProduct?.sku === p.sku)
      ?.quantity || 0;

  const stock = newlyAddedProduct?.items[0].warehouses
    ? newlyAddedProduct?.items[0].warehouses.reduce(
        (total, w) => total + w.stock,
        0
      )
    : newlyAddedProduct?.items[0].stock;

  const productIsOutOfStock =
    insideCart >= Number(stock) && productHasLimitedStock;

  if (newlyAdded) {
    if (Number(stock) - insideCart >= 0) {
      newlyAddedDeliveryLabel = tProduct("inStock");
    } else if (
      newlyAddedProduct?.delivery_status &&
      newlyAddedProduct.delivery
    ) {
      newlyAddedDeliveryLabel = `${newlyAddedProduct.delivery_status} (${newlyAddedProduct.delivery})`;
    } else if (productHasLimitedStock && Number(stock) === insideCart) {
      newlyAddedDeliveryLabel = tProduct("noMoreInStock");
    }
  }

  if (isLoading) {
    return (
      <div
        className={classNames("Cart", {
          "is-visible": showCart,
        })}
      >
        <header className="Cart-header">
          <h2 className="Cart-title">{t("heading")}</h2>
        </header>

        <div className="Cart-content">{t("loading")}</div>
      </div>
    );
  }

  const artilleriet_currency = localStorage.getItem("artilleriet_currency");
  const selection_eur = currency === "SEK" ? JSON.parse(localStorage.getItem("selection_eur")) : null;

  const cart = (
    <div
      className={classNames("Cart", {
        "Cart--checkout": context === "checkout",
        "is-visible": showCart || context === "checkout",
        "is-loading": isAdjusting,
      })}
      ref={cartRef}
    >
      <header className="Cart-header">
        <h2 className="Cart-title">{t("heading")}</h2>

        <button
          className="Cart-closeButton"
          onClick={() => setShowCart(false)}
          type="button"
        >
          <span>{t("closeButton")}</span>
          <i className="Icon Icon--clear" />
        </button>
      </header>

      <div className="Cart-contentTop">
        {newlyAdded ? (
          <div className="Cart-newlyAdded">
            <div className="Cart-newlyAddedImageWrapper">
              {!hasGiftCertificate && (
                <CentraProductLink product={newlyAdded.product}>
                  <CentraProductImage
                    className="Cart-newlyAddedImage"
                    src={newlyAdded.product.media?.portrait?.[0]}
                    alt={newlyAdded.product.name}
                  />
                </CentraProductLink>
              )}
            </div>

            <div className="Cart-newlyAddedInfo">
              <div className="Cart-newlyAddedMeta">
                <div className="Cart-newlyAddedBrand">
                  {newlyAdded.product.no_brand_hidden === "1"
                    ? ""
                    : newlyAdded.product.brandName}
                </div>

                <button
                  className="Cart-newlyAddedRemove"
                  disabled={isAdjusting}
                  onClick={() => setItemQuantity(newlyAdded, 0)}
                  type="button"
                >
                  {t("removeItemButton")}
                </button>
              </div>

              <h5 className="Cart-newlyAddedTitle">
                <CentraProductLink product={newlyAdded.product}>
                  {newlyAdded.product.name}
                </CentraProductLink>
              </h5>

              <div className="Cart-newlyAddedShipping">
                {newlyAddedDeliveryLabel === tProduct("inStock") ? (
                  <div className="Cart-newlyAddedAvailabilityIcon" />
                ) : null}

                {newlyAddedDeliveryLabel}
              </div>
            </div>

            <div className="Cart-newlyAddedQuantity">
              <button
                className="Cart-newlyAddedQuantityButton"
                disabled={isAdjusting}
                onClick={() =>
                  setItemQuantity(newlyAdded, newlyAdded.quantity - 1)
                }
                type="button"
              >
                <i className="Icon Icon--minus" />
              </button>

              <input
                className="Cart-newlyAddedQuantityInput"
                disabled={true}
                onChange={(event) => {
                  const value = event.currentTarget.valueAsNumber;
                  if (
                    productHasLimitedStock &&
                    isNumber(stock) &&
                    insideCart + value >= stock
                  ) {
                    setItemQuantity(newlyAdded, stock);
                  } else {
                    setItemQuantity(newlyAdded, value);
                  }
                }}
                step="1"
                min="1"
                type="number"
                value={newlyAdded.quantity}
              />

              <button
                className="Cart-newlyAddedQuantityButton"
                disabled={isAdjusting || productIsOutOfStock}
                onClick={() =>
                  setItemQuantity(newlyAdded, newlyAdded.quantity + 1)
                }
                type="button"
              >
                <i className="Icon Icon--plus" />
              </button>
            </div>

            <div
              className={classNames("Cart-newlyAddedPrice", {
                "is-discounted": newlyAdded.product.showAsOnSale,
              })}
            >
              {newlyAdded.product.showAsOnSale ? (
                <span>
                  {newlyAdded.totalPriceBeforeDiscount}
                  {doodle && <img src={doodle} alt="" />}
                </span>
              ) : null}

              {newlyAdded.totalPrice}
            </div>
          </div>
        ) : null}
      </div>

      <div className="Cart-contentMiddle">
        <ul className="Cart-items">
          {cartItems?.map((item, index) => {
            const item_eur = selection_eur?.items[index];
            return (
              <CartItem
                currency={currency}
                key={item.line}
                item={item}
                item_eur={item_eur}
                disabled={isAdjusting}
                setQuantity={setItemQuantity}
              />
            );
          })}
        </ul>
      </div>

      <div className="Cart-contentBottom">
        <ul className="Cart-items--maxHeight">
          {!hasGiftCertificate && (
            <li className="Cart-item Cart-item--shipping">
              <div className="Cart-itemMeta">
                <span className="Cart-itemBrand">{t("shippingHeading")}</span>
              </div>

              <div className="Cart-itemBody">
                <h5 className="Cart-itemTitle">
                  {data?.selection.shippingMethodName}
                </h5>

                <div className="Cart-itemPrice">
                  {data?.selection.totals.shippingPrice}
                </div>
              </div>
            </li>
          )}

          {data?.selection.discounts.vouchers.map((v) => {
            return (
              <li className="Cart-item Cart-item--shipping" key={v.voucher}>
                <div className="Cart-itemMeta">
                  <span className="Cart-itemBrand">{t("voucherHeading")}</span>

                  <button
                    className="Cart-itemRemove"
                    disabled={isAdjusting}
                    onClick={() => removeVoucher(v.voucher)}
                    type="button"
                  >
                    {t("removeVoucherButton")}
                  </button>
                </div>

                <div className="Cart-itemBody">
                  <h5 className="Cart-itemTitle">{v.voucher}</h5>

                  <div className="Cart-itemPrice Cart-itemPrice--discount">
                    {v.priceOff}
                  </div>
                </div>
              </li>
            );
          })}

          {context === "checkout" && !hasGiftCertificate ? (
            <li className="Cart-item Cart-item--shipping">
              <label className="Cart-itemBody Cart-itemBody--checkbox">
                <input
                  className="Checkout-toggleCheckbox"
                  checked={addVoucherEnabled}
                  name="add_voucher_enabled"
                  type="checkbox"
                  onChange={(event) =>
                    setAddVoucherEnabled(event.target.checked)
                  }
                />

                <div className="Checkout-toggleFauxCheckbox">
                  <i className="Icon Icon--checkmark"></i>
                </div>

                <h5 className="Cart-itemTitle Cart-itemTitle--smaller">
                  {t("vouchers.heading")}
                </h5>
              </label>
            </li>
          ) : null}

          {context === "checkout" && addVoucherEnabled ? (
            <li className="Cart-item Cart-item--shipping">
              <form
                className="Checkout-voucherForm"
                onSubmit={(event) => {
                  event.preventDefault();
                  submitApplyVoucher(event);
                }}
              >
                <label
                  className={classNames("Checkout-label Checkout-label--full", {
                    "is-active": activeInput === "voucher",
                  })}
                >
                  <span className="Checkout-labelText">
                    {t("vouchers.inputLabel")}
                  </span>

                  <input
                    className="Checkout-input"
                    disabled={voucherFormStatus === "submitting"}
                    name="voucher"
                    required
                    type="text"
                    onFocus={() => setActiveInput("voucher")}
                    onBlur={() => setActiveInput("")}
                  />
                </label>

                <button
                  className="Checkout-applyVoucher"
                  disabled={voucherFormStatus === "submitting"}
                  type="submit"
                >
                  {t("vouchers.addButton")}
                </button>
              </form>

              {voucherFormResponse ? (
                <div
                  className={classNames(
                    "Cart-voucherFormResponse",
                    "is-" + voucherFormStatus
                  )}
                >
                  {voucherFormResponse}
                </div>
              ) : null}
            </li>
          ) : null}

          {
            // context === "checkout" && !hasGiftCertificate ? (
            //   <li className="Cart-item Cart-item--shipping">
            //     <label className="Cart-itemBody Cart-itemBody--checkbox">
            //       <input
            //         className="Checkout-toggleCheckbox"
            //         checked={giftWrap}
            //         name="gift_wrap"
            //         type="checkbox"
            //         onChange={(event) => setGiftWrap(event.target.checked)}
            //       />
            //       <div className="Checkout-toggleFauxCheckbox">
            //         <i className="Icon Icon--checkmark"></i>
            //       </div>
            //       <h5 className="Cart-itemTitle Cart-itemTitle--smaller">
            //         <span>{t("giftWrapCheckbox")}</span>
            //       </h5>
            //     </label>
            //   </li>
            // ) : null
          }
        </ul>

        <div className="Cart-footer">
          <dl className="Cart-footerDetails">
            {data?.selection.discounts.anyDiscount ? (
              <div className="Cart-footerDetailRow Cart-footerDetailRow--discount">
                <dt>{t("discountHeading")}</dt>
                <dd>
                  {data?.selection.totals.totalDiscountPrice}
                </dd>
              </div>
            ) : null}

            <div className="Cart-footerDetailRow">
              <dt>{t("grandTotal")}</dt>
              <dd>
                {data?.selection.totals.grandTotalPrice}
              </dd>
            </div>

            <div className="Cart-footerDetailRow">
              <dt>
                {t("includingTax", { n: data?.selection.totals.taxPercent })}
              </dt>
              <dd>
                {data?.selection.totals.grandTotalPriceTax}
              </dd>
            </div>
          </dl>

          {context !== "checkout" ? (
            <Link
              className={classNames("Cart-checkoutLink", {
                "is-disabled": !data?.selection.items.length,
              })}
              href="/checkout"
            >
              {t("checkoutLink")}
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {context === "sidebar" ? (
        <div
          className={classNames("Cart-backdrop", {
            "is-visible": showCart,
          })}
          onClick={() => setShowCart(false)}
          onMouseEnter={() => {
            if (!isTransitioning) {
              setShowCart(false);
            }
          }}
        />
      ) : null}

      {context === "checkout" &&
      artilleriet_currency &&
      artilleriet_currency === "EUR" ? (
        <div className="Checkout Checkout--checkoutPage Cart-notice">
          {t("currencyNotice")}
        </div>
      ) : null}

      {cart}
    </>
  );
}
