/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState, useRef } from "react";
import useSWR, { useSWRConfig } from "swr";
import classNames from "classnames";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import Script from "next/script";
import camelCase from "lodash/camelCase";
import find from "lodash/find";
import get from "lodash/get";
import last from "lodash/last";
import { useTranslations } from "next-intl";

import { isNumber } from "lodash";
import { StoryContext } from "../pages/[...route]";
import {
  centraGet,
  centraGetProduct,
  centraPost,
  getCanonicalProductCat,
  getProductBrandCat,
} from "../lib/centra-api";
import {
  useBodyClass,
  useMediaQuery,
  useRandomDoodle,
  useCentraToken,
  useScrollIntoView,
  voyadoCart,
  getVoyadoItems,
} from "../lib/utils";
import { StoryWithChildren } from "../lib/storyblok-api";
import Link from "./Link";
import CentraProductLink from "./CentraProductLink";
import CentraProductImage from "./CentraProductImage";
import CustomerServicePopup from "./CustomerServicePopup";
import FaqPanel from "./FaqPanel";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "klarna-placement": any;
    }
  }
  interface Window {
    KlarnaOnsiteService: any;
  }
}

function extractBetween(
  string: string,
  from: string | RegExp,
  to: string | RegExp,
  includeFrom = true
) {
  if (!(from instanceof RegExp)) {
    from = new RegExp(from);
  }

  if (!(to instanceof RegExp)) {
    to = new RegExp(to);
  }

  const fromResult = string.match(from);

  if (fromResult) {
    const fromIndex = includeFrom
      ? fromResult.index
      : fromResult.index + fromResult[0].length;
    const toResult = string.slice(fromIndex).match(to);

    if (toResult) {
      return string.slice(fromIndex, fromIndex + toResult.index).trim();
    }
  }

  return "";
}

function generateDefinitionList(string: string) {
  const paragraphs = string.match(/<p>([\S\s]*?)<\/p>/g);

  if (!paragraphs) {
    return "";
  }

  const listItems = paragraphs
    .reduce((result, p) => {
      const termMatch = p.match(/^<p>\s*<strong>(.*?)<\/strong>/);

      if (!termMatch) {
        const index = Math.max(0, result.length - 1);

        if (result[index]) {
          result[index] += p;
        } else {
          result[index] = p;
        }
      } else {
        result.push(p);
      }

      return result;
    }, [])
    .map((p) => {
      p = p.trim();

      const termMatch = p.match(/^<p>\s*<strong>(.*?)<\/strong>/);
      const details = p
        .slice(termMatch?.[0].length)
        .trim()
        .replace(/^<br \/>/, "")
        .replace(/<p>/, "<br>")
        .replace(/<\/p>/, "");

      return termMatch
        ? `<div><dt>${termMatch?.[1]}</dt><dd>${details}</dd></div>`
        : p;
    });

  return `<dl>${listItems.join("")}</dl>`;
}

export function getProductAttributesAndChoices(
  product: CentraProduct,
  variants: CentraProduct[]
) {
  const t = useTranslations("ProductPage");

  if (!product) {
    return [];
  }

  const attributes = [
    ["swatch.desc", t("attrsSingular.color"), t("attrsPlural.color")],
    ["size", t("attrsSingular.size"), t("attrsPlural.size")],
    ["frame", t("attrsSingular.frame"), t("attrsPlural.frame")],
    ["style", t("attrsSingular.style"), t("attrsPlural.style")],
  ];

  const attributesAndChoices = attributes
    // Filter out attributes that are not in use on this product
    .filter(([name]) => variants?.find((p) => get(p, name)))
    // Map attributes to objects with { name, label, choices }
    .map(([name, label, pluralLabel], i, attrs) => {
      const allChoices: [string, CentraProduct][] = variants
        .filter((p) => {
          for (const [n] of attrs) {
            if (
              n !== name &&
              get(product, n) &&
              get(product, n) !== get(p, n)
            ) {
              return false;
            }
          }
          return true;
        })
        .map((p) => [get(p, name), p]);

      const choicesMap = new Map(allChoices);
      const choices = [...choicesMap.values()];

      return {
        name,
        label,
        pluralLabel,
        choices,
      };
    })
    .filter((a) => a.choices.length);

  return attributesAndChoices;
}

type BreadcrumbStory = Pick<
  StoryWithChildren,
  "full_slug" | "name" | "content"
>;

export default function PageProduct({
  productId,
  currency,
}): React.ReactElement {
  const context = useContext(StoryContext);
  const router = useRouter();
  const [token] = useCentraToken();
  const { cache, mutate } = useSWRConfig();
  const t = useTranslations("ProductPage");
  const tContactForm = useTranslations("ContactForm");

  const { data } = useSWR(
    `centraproduct://${productId}/${router.locale}/${currency}`,
    (uri) => centraGetProduct(uri, token)
  );
  const product: CentraProduct = data?.product;

  const { data: selectionData } = useSWR(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`,
    (uri) => centraGet(uri, token)
  );

  const category = getCanonicalProductCat(product);
  const brandCat = getProductBrandCat(product);

  let activeCategoryFromUri: BreadcrumbStory = context.menuItems
    .slice(0)
    .sort((a, b) => b.full_slug.length - a.full_slug.length)
    .find((m) => router.asPath.startsWith("/" + m.full_slug));
  let breadcrumbs: BreadcrumbStory[];

  if (!activeCategoryFromUri && category) {
    activeCategoryFromUri = {
      full_slug: category.uri,
      name: category.name[category.name.length - 1],
      content: {
        _uid: "dummy",
        component: "category",
        centra: {
          id: category.category,
        },
      },
    };
  }

  if (activeCategoryFromUri) {
    breadcrumbs = activeCategoryFromUri.full_slug
      .split("/")
      .map((r, i, res) => res.slice(0, i + 1).join("/"))
      .map((slug) => context.menuItems.find((m) => m.full_slug === slug))
      // It's unlikely that the array contains empty items, but it is possible
      .filter(Boolean);
  }

  const [amount, setAmount] = useState(1);
  const [activeSelect, setActiveSelect] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [activeZoom, setActiveZoom] = useState(false);
  const [addToCartStatus, setAddToCartStatus] = useState<"loading" | "">("");
  const [outOfStockFormStatus, setOutOfStockFormStatus] = useState("");
  const [outOfStockEmail, setOutOfStockEmail] = useState("");
  const [contactCSPopupOpen, setContactCSPopupOpen] = useState(false);
  const [faqPanelOpen, setFaqPanelOpen] = useState(false);

  const doodle = useRandomDoodle();
  const productRef = useRef<HTMLDivElement>();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const breadcrumbsRef = useRef<HTMLDivElement>();
  const breadcrumbsContentRef = useRef<HTMLUListElement>();
  const sidebarRef = useRef<HTMLDivElement>();

  useScrollIntoView(breadcrumbsContentRef, ".Breadcrumbs-item a.is-active");

  useEffect(() => {
    if (!breadcrumbsRef.current || !sidebarRef.current) {
      return;
    }

    let latestScrollTop = 0;
    let lastReactedScrollTop = 0;
    let builtUpScrollTop = 0;
    // const breadcrumbsTranslateY = 0;
    let sidebarTranslateY = 0;
    let animationFrameId: number;

    function transformElement() {
      if (!breadcrumbsRef.current || !sidebarRef.current) {
        return;
      }

      builtUpScrollTop += latestScrollTop - lastReactedScrollTop;

      const breadcrumbsHeight = breadcrumbsRef.current.offsetHeight;

      // breadcrumbsTranslateY = clamp(
      //   -breadcrumbsHeight,
      //   0,
      //   breadcrumbsTranslateY - builtUpScrollTop
      // );

      // breadcrumbsRef.current.style.transform = `translateY(${breadcrumbsTranslateY}px)`;

      if (!isMobile) {
        const sidebarHeightOutsideViewport =
          window.innerHeight - sidebarRef.current.offsetHeight;

        const min = Math.min(sidebarHeightOutsideViewport, breadcrumbsHeight);

        sidebarTranslateY = clamp(
          min,
          breadcrumbsHeight * 2,
          sidebarTranslateY - builtUpScrollTop
        );

        // Using `top` isn't ideal from a performance perspective, but it has
        // one huge advantage over `transform`. Namely the fact that the sticky
        // position in relation to the top and the bottom of the container is
        // handled by the browser rather than by our manual calculations
        sidebarRef.current.style.top = `${sidebarTranslateY}px`;
      }

      builtUpScrollTop = 0;
      lastReactedScrollTop = latestScrollTop;
    }

    function clamp(min, max, value) {
      return Math.min(Math.max(value, min), max);
    }

    function handleScroll() {
      latestScrollTop = Math.max(0, window.scrollY);
      animationFrameId = requestAnimationFrame(transformElement);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [breadcrumbsRef.current, sidebarRef.current]);

  useEffect(() => {
    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    window.KlarnaOnsiteService.push({ eventName: "refresh-placements" });
  }, []);

  function toggleActiveSelect(variant: string) {
    setActiveSelect(activeSelect === variant ? "" : variant);
  }

  useEffect(() => {
    function cickToCloseSelect(event) {
      const select = event.target.closest(".Product-variantSelect");

      if (!select) {
        setActiveSelect("");
      }
    }

    document.addEventListener("click", cickToCloseSelect);

    return () => {
      document.removeEventListener("click", cickToCloseSelect);
    };
  }, []);

  useBodyClass(
    classNames({
      "is-zooming": activeZoom,
    })
  );

  async function registerStockNotification(event) {
    event.preventDefault();

    setOutOfStockFormStatus("submitting");

    try {
      const data = await centraPost(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/back-in-stock-subscription/`,
        token,
        {
          email: outOfStockEmail,
          shipTo: {
            country: selectionData?.location.country,
          },
          item: product?.items[0].item,
          language: router.locale,
        }
      );

      if (data.token) {
        setOutOfStockFormStatus("success");
      }
    } catch (error) {
      console.log("Error submitting out of stock notice", error);
      setOutOfStockFormStatus("error");
    }
  }

  async function addToCart() {
    const item = product.items[0];
    const adjustAmount = amount;

    // const insideCart =
    //   selectionData.selection?.items?.find((p) => item.item === p.item)
    //     ?.quantity || 0;

    // if (isNumber(item.stock)) {
    //   if (insideCart + amount > item.stock) {
    //     adjustAmount = item.stock - insideCart;
    //     if (adjustAmount < 0) {
    //       adjustAmount = 0;
    //     }
    //     setAmount(adjustAmount);
    //   }
    // }

    if (adjustAmount) {
      const data = await centraPost(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/items/${item.item}`,
        token,
        { quantity: adjustAmount }
      );

      mutate(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`,
        data,
        false
      );

      const cartItems = getVoyadoItems(selectionData);
      voyadoCart(token, router.locale, [
        ...cartItems,
        { itemId: item.sku, quantity: amount },
      ]);

      const dataLayer = window.dataLayer || [];
      const currency = product.price.split(/(\s+)/).slice(-1)[0];

      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "eec.addToCart",
        event_id: new Date().getTime(),
        ecommerce: {
          currencyCode: currency || "N/A",
          add: {
            products: [
              {
                name: product.name || "N/A",
                id: product.product || "N/A",
                price: String(product.priceAsNumber) || "N/A",
                brand: product.brandName || "N/A",
                category: product.categoryUri || "N/A",
                variant: product.variantName || "N/A",
              },
            ],
          },
        },
      });

      setAmount(1);

      const event = new CustomEvent("artilleriet.addedtocart", {
        detail: { product, item, quantity: adjustAmount },
      });
      window.dispatchEvent(event);
    }
  }

  // Certain products - eg. plates - with standing images have a lot of
  // whitespace at the top. To prevent issues where users don't actually see
  // the product after opening the product page, we support the option of
  // moving the first image in the gallery to the second place. This is done
  // by adding "shift" to the end of the image's filename (before the file
  // extension)
  const shiftFirstImage = /shift-portrait\.(jpe?g|png|webp)$/i.test(
    product.media.portrait?.[0]
  );

  const productFiles = extractBetween(
    product.descriptionHtml,
    /<strong>(Files|Filer):<\/strong>/,
    /<\/p>/
  )
    .replace(/<strong>Files:<\/strong>/, "")
    .replace(/<\w+\s*\/?>/g, "");

  const productVideo = extractBetween(
    product.descriptionHtml,
    /\[video=/,
    /\]/,
    false
  );

  let productDesc = extractBetween(product.descriptionHtml, /^/, /<p><strong>/)
    .replace(productFiles, "")
    .replace(/\[video=.*?\]/, "")
    .replace(/<p><\/p>/g, "");

  let productInfo = product.descriptionHtml
    .replace(productDesc, "")
    .replace(productFiles, "")
    .replace(/\[video=.*?\]/, "")
    .replace(/<p><\/p>/g, "");
  productInfo = generateDefinitionList(productInfo);

  if (!productInfo && !productDesc && product.descriptionHtml) {
    productDesc = product.descriptionHtml
      .replace(productFiles, "")
      .replace(/\[video=.*?\]/, "");
  }

  const brandStory = context.menuItems.find(
    (m) => m.full_slug === brandCat.uri
  );
  const brandDescription = brandStory?.content?.description?.content;

  const centraImageSizes =
    typeof window !== "undefined" ? window.CENTRA_IMAGE_SIZES : {};
  const productMedia = product.media.portrait
    ? product.media.portrait.slice(0)
    : [];
  if (shiftFirstImage) {
    const firstImage = productMedia.shift();
    productMedia.splice(1, 0, firstImage);
  }
  if (productVideo) {
    productMedia.splice(1, 0, productVideo);
  }
  const [imageSizes, setImageSizes] = useState(
    productMedia.map((src) => centraImageSizes[src] || [748, 995])
  );

  useEffect(() => {
    productMedia.forEach((src, i) => {
      window.CENTRA_IMAGE_SIZES[src] = imageSizes[i];
    });
  }, imageSizes.flat());

  const productIsPurchasable =
    product.available && product.skin_product_hidden !== "1";

  // "Empty" means product is limited and will not be in stock again
  // and will not display out of stock notice form, "Sold Out" means
  // product is limited but might be in stock again, and should
  // hence show stock notice form
  const productHasLimitedStock =
    product?.delivery === "Empty" ||
    product?.delivery === "Sold Out" ||
    product.delivery === ""; // legacy attribute

  const klarnaIsAvailable = selectionData?.paymentMethods.some(
    (p) => p.paymentMethodType === "klarna_checkout"
  );

  const [productVariants, setProductVariants] = useState([]);
  const [isLoadingVariants, setIsLoadingVariants] = useState(
    Boolean(!productVariants.length && product.relatedProducts.length)
  );

  useEffect(() => {
    async function fetchProductVariants() {
      if (!product.relatedProducts.length) {
        return;
      }

      const productIds = product.relatedProducts.map((p) => p.product);
      const currencies = ["EUR", "SEK"]; // Add the currencies you want to load data for
      const products = [];

      await Promise.all(
        productIds.map(async (id) => {
          await Promise.all(
            currencies.map(async (currency) => {
              const uri = `centraproduct://${id}/${router.locale}/${currency}`;

              if (!cache.get(uri)) {
                const result = await centraGetProduct(uri, token);
                mutate(uri, { token, product: result.product }, false);
              }
            })
          );
          products.push(
            cache.get(`centraproduct://${id}/${router.locale}/${currency}`)
              .product
          );
        })
      );

      setIsLoadingVariants(false);
      setProductVariants(products.filter((p) => p.skin_product_hidden !== "1"));
    }

    fetchProductVariants();
  }, [product.product]);

  useEffect(() => {
    const dataLayer = window.dataLayer || [];
    const currency = product.price?.split(/(\s+)/).slice(-1)[0];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "eec.detail",
      event_id: new Date().getTime(),
      ecommerce: {
        currencyCode: currency || "N/A",
        detail: {
          products: [
            {
              name: product.name || "N/A",
              id: product.product || "N/A",
              price: String(product.priceAsNumber) || "N/A",
              brand: product.brandName || "N/A",
              category: product.categoryUri || "N/A",
              variant: product.variantName || "N/A",
            },
          ],
        },
      },
    });
  }, []);

  const attributesAndChoices = getProductAttributesAndChoices(
    product,
    productVariants
  );

  const variantsExistButThereAreNoAttributes =
    productVariants.length && !attributesAndChoices.length;

  const tabs = [
    {
      condition: productDesc,
      content: productDesc,
      label: t("tabs.description"),
    },
    {
      condition: productInfo,
      content: productInfo,
      label: t("tabs.specification"),
    },
    {
      condition: product.no_brand_hidden !== "1" && brandDescription,
      content: brandDescription,
      label: t("tabs.aboutBrand"),
    },
    {
      condition: product.designer?.length,
      content: "",
      label: t("tabs.designer"),
    },
    {
      condition: productFiles,
      content: productFiles,
      label: t("tabs.downloads"),
    },
  ];

  const tabCount = tabs.reduce((sum, t) => sum + (t.condition ? 1 : 0), 0);

  const productPriceLabel =
    product.skin_product_hidden === "1" || product.contact_only_text === "1"
      ? t("fromPrice", { price: product.price })
      : product.price;

  let addToCartLabel = "";

  const item = product.items[0];
  const stockData = item.stock;
  const stockNumber = Number(stockData);

  const insideCart =
    selectionData?.selection?.items?.find((p) => item.item === p.item)
      ?.quantity || 0;

  const displayLowestPriceNotice =
    product.lowestPrice[0]?.priceAsNumber &&
    product.showAsOnSale &&
    product.priceBeforeDiscountAsNumber !==
      product.lowestPrice[0]?.priceAsNumber;

  const showOutOfStockForm =
    ((product.delivery === "Sold Out" ||
      camelCase(product.delivery) === "comingSoon") &&
      stockData === 0) ||
    ((product.delivery === "Sold Out" ||
      camelCase(product.delivery) === "comingSoon") &&
      insideCart >= stockData);

  if (insideCart > 0 && isNumber(item.stock) && insideCart === item.stock) {
    addToCartLabel = t("noMoreInStock");
  } else if (
    !product.available ||
    (productHasLimitedStock && stockNumber === 0)
  ) {
    if (camelCase(product.delivery) === "comingSoon") {
      addToCartLabel = t("comingSoon");
    } else {
      addToCartLabel = t("outOfStock");
    }
  } else if (addToCartStatus === "loading") {
    addToCartLabel = t("adding");
  } else {
    addToCartLabel = t("addToBag");
  }

  const [deliveryLabel, setDeliveryLabel] = useState("");

  const updateDeliveryLabel = () => {
    const insideCart: number =
      selectionData?.selection?.items?.find((p) => item.item === p.item)
        ?.quantity || 0;

    if (stockNumber > 0 && stockNumber >= insideCart + amount) {
      setDeliveryLabel(t("inStock"));
    } else if (camelCase(product.delivery) === "comingSoon") {
      setDeliveryLabel(null);
    } else if (
      (!productHasLimitedStock &&
      product.delivery_status) || (productHasLimitedStock && (stockNumber === 0 || stockNumber < insideCart + amount) &&
      product.delivery_status)
    ) {
      setDeliveryLabel(`${product.delivery_status} (${product.delivery})`);
    } else {
      setDeliveryLabel(t("outOfStock"));
    }

    if (
      insideCart > 0 && // user has items in cart
      isNumber(item.stock) && // stock is not "No"
      insideCart >= item.stock && // user has filled cart with full stock
      productHasLimitedStock
    ) {
      addToCartLabel = t("noMoreInStock"); // user added items to cart so product became OOS
    } else if (!product.available) {
      if (camelCase(product.delivery) === "comingSoon") {
        addToCartLabel = t("comingSoon");
      } else {
        addToCartLabel = t("outOfStock"); // product is OOS
      }
    } else if (addToCartStatus === "loading") {
      addToCartLabel = t("adding");
    } else {
      addToCartLabel = t("addToBag");
    }
  };

  useEffect(() => {
    if (stockData && amount) {
      updateDeliveryLabel();
    }
  }, [amount, selectionData, stockData]);

  const productNode = (
    <section className="Product" ref={productRef}>
      <div
        className="Product-images"
        onClick={() => setActiveZoom(!activeZoom)}
      >
        <div
          className="Product-openImageZoom"
          onClick={() => setActiveZoom(true)}
        >
          <i className="Icon Icon--zoomOut" />
        </div>

        {activeZoom ? (
          <div className="Product-closeImageZoom">
            <i className="Icon Icon--clear" />
          </div>
        ) : null}

        {productMedia.map((image, i) => {
          const isVideo = /\.mp4$/.test(image);
          const isLandscape = imageSizes[i][0] > imageSizes[i][1];
          let sizes: string;

          if (activeZoom) {
            sizes = "100vw";
          } else if (isLandscape) {
            sizes = "(min-width: 768px) 65vw, 200vw";
          } else {
            sizes = "(min-width: 768px) 65vw, 100vw";
          }

          return (
            <div
              className={classNames("Product-image", {
                "Product-image--video": isVideo,
                "is-landscape": isLandscape,
              })}
              key={image}
            >
              {isVideo ? (
                <video src={image} autoPlay muted playsInline loop />
              ) : (
                <CentraProductImage
                  src={image}
                  height={isLandscape ? 663 : null}
                  width={isLandscape ? 995 : null}
                  sizes={sizes}
                  onDimensionsLoad={(width, height) => {
                    const newImageSizes = imageSizes.map((s, j) =>
                      i === j ? [width, height] : s
                    );
                    setImageSizes(newImageSizes);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>

      <div className="Product-contentColumn">
        <div className="Product-contentInner" ref={sidebarRef}>
          {product.no_brand_hidden !== "1" ? (
            <div className="Product-brandRow">
              <Link href={"/" + brandCat.uri}>{product.brandName}</Link>
            </div>
          ) : null}

          <div className="Product-titleRow">
            <h1 className="Product-title">{product.name}</h1>

            {product.price ? (
              <h2
                className={classNames("Product-price", {
                  "is-discounted": product.showAsOnSale,
                })}
              >
                {product.showAsOnSale ? (
                  <>
                    <del>
                      {product.priceBeforeDiscount}
                      {doodle && <img src={doodle} alt="" />}
                    </del>
                    <ins>{productPriceLabel}</ins>
                  </>
                ) : (
                  productPriceLabel
                )}
              </h2>
            ) : null}
            {displayLowestPriceNotice && (
              <div className="Product-lowestPrice">
                <span>
                  {t("lowestPriceText")}: {product.lowestPrice[0].price}
                </span>
              </div>
            )}
          </div>

          {deliveryLabel && product.skin_product_hidden !== "1" ? (
            <div className="Product-availability">
              {deliveryLabel === t("inStock") ? (
                <div className="Product-availabilityIcon" />
              ) : null}

              <span>
                {t("deliveryStatus")}: {deliveryLabel}
              </span>

              <button
                className="Product-availabilityInfoButton"
                aria-label={t("deliveryStatusInfoButton")}
                onClick={() => setFaqPanelOpen(true)}
              >
                <i className="Icon Icon--info" />
              </button>
            </div>
          ) : null}

          {isLoadingVariants ? (
            <div className="Product-variantSelect">
              <div className="Product-variantSelectSelection">
                <div className="Product-variantSelectItem">
                  <div className="Product-variantSelectText">
                    <span>{t("loading")}</span>
                  </div>
                </div>

                <span className="Product-variantSelectIcon">
                  <i className="Icon Icon--arrowRight" />
                </span>
              </div>
            </div>
          ) : null}

          {variantsExistButThereAreNoAttributes ? (
            <div
              className={classNames("Product-variantSelect", {
                "is-open": activeSelect === "variant",
              })}
            >
              <div
                className="Product-variantSelectSelection"
                onClick={() => toggleActiveSelect("variant")}
              >
                <div className="Product-variantSelectItem">
                  {product.skin_product_hidden !== "1" &&
                  product.media.portrait?.[0] ? (
                    <div className="Product-variantSelectImage">
                      <CentraProductImage
                        src={product.media.portrait[0]}
                        alt={product.name}
                        layout="fill"
                        sizes="10vw"
                      />
                    </div>
                  ) : null}

                  <div className="Product-variantSelectText">
                    <span>
                      {product.skin_product_hidden !== "1"
                        ? product.name
                        : t("chooseOption")}
                    </span>
                  </div>
                </div>

                <span className="Product-variantSelectIcon">
                  <i className="Icon Icon--arrowRight" />
                </span>
              </div>

              <ul className="Product-variantSelectDropdown">
                {productVariants.map((variant) => (
                  <li
                    className={classNames("Product-variantSelectDropdownItem", {
                      "is-selected": variant.product === product.product,
                    })}
                    key={variant.product}
                  >
                    <CentraProductLink
                      className="Product-variantSelectItem"
                      category={activeCategoryFromUri?.content?.centra.id}
                      maybeForceCategory
                      product={variant}
                      replace
                      scroll
                      shallow
                    >
                      {variant.media.portrait?.[0] ? (
                        <div className="Product-variantSelectImage">
                          <CentraProductImage
                            src={variant.media.portrait[0]}
                            alt={variant.name}
                            layout="fill"
                            sizes="10vw"
                          />
                        </div>
                      ) : null}

                      <div className="Product-variantSelectText">
                        <span>{variant.name}</span>
                      </div>
                    </CentraProductLink>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {attributesAndChoices.map((a) => (
            <div
              className={classNames("Product-variantSelect", {
                "is-open": activeSelect === a.name,
              })}
              key={a.name}
            >
              <div
                className="Product-variantSelectSelection"
                onClick={() => toggleActiveSelect(a.name)}
              >
                <div className="Product-variantSelectItem">
                  {get(product, a.name) &&
                    !(
                      attributesAndChoices.length > 1 &&
                      (a.name === "size" || a.name === "frame")
                    ) &&
                    product.media.portrait?.[0] && (
                      <div className="Product-variantSelectImage">
                        <CentraProductImage
                          src={product.media.portrait[0]}
                          alt={get(product, a.name)}
                          layout="fill"
                          sizes="10vw"
                        />
                      </div>
                    )}

                  <div className="Product-variantSelectText">
                    <span>
                      {get(product, a.name)
                        ? `${a.label}: ${get(product, a.name)}`
                        : t("chooseLabel", { label: a.label })}
                    </span>
                  </div>
                </div>

                <span className="Product-variantSelectIcon">
                  <i className="Icon Icon--arrowRight" />
                </span>
              </div>

              <ul className="Product-variantSelectDropdown">
                {a.choices.map((variant) => (
                  <li
                    className={classNames("Product-variantSelectDropdownItem", {
                      "is-selected": variant.product === product.product,
                    })}
                    key={variant.product}
                  >
                    <CentraProductLink
                      className="Product-variantSelectItem"
                      category={activeCategoryFromUri?.content?.centra.id}
                      maybeForceCategory
                      product={variant}
                      replace
                      scroll
                      shallow
                    >
                      {!(
                        attributesAndChoices.length > 1 && a.name === "size"
                      ) && variant.media.portrait?.[0] ? (
                        <div className="Product-variantSelectImage">
                          <CentraProductImage
                            src={variant.media.portrait[0]}
                            alt={get(variant, a.name)}
                            layout="fill"
                            sizes="10vw"
                          />
                        </div>
                      ) : null}

                      <div className="Product-variantSelectText">
                        <span>{get(variant, a.name)}</span>
                      </div>
                    </CentraProductLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          {product.contact_only_text !== "1" &&
          product.skin_product_hidden !== "1" ? (
            <div
              className={classNames("Product-addToCartForm", {
                "is-inactive":
                  !productIsPurchasable ||
                  (productHasLimitedStock && stockData === 0),
              })}
            >
              <div className="Product-quantity">
                <button
                  className="Product-quantityButton"
                  disabled={!productIsPurchasable}
                  onClick={() => setAmount(Math.max(1, amount - 1))}
                  type="button"
                  aria-label={t("subtractQty")}
                >
                  <i className="Icon Icon--minus" />
                </button>

                <input
                  className="Product-quantityInput"
                  disabled={
                    !productIsPurchasable ||
                    (productHasLimitedStock &&
                      (amount === stockNumber ||
                        stockNumber === 0)) ||
                    (productHasLimitedStock &&
                      insideCart > 0 &&
                      isNumber(stockData) &&
                      insideCart + amount >= stockNumber)
                  }
                  onChange={(event) => {
                    const value = event.currentTarget.valueAsNumber;
                    if (
                      productHasLimitedStock &&
                      value > stockNumber - insideCart
                    ) {
                      setAmount(stockNumber - insideCart);
                    } else {
                      setAmount(event.currentTarget.valueAsNumber);
                    }
                  }}
                  step="1"
                  min="1"
                  type="number"
                  value={amount}
                  aria-label={t("qty")}
                />

                <button
                  className="Product-quantityButton"
                  disabled={
                    !productIsPurchasable ||
                    (productHasLimitedStock &&
                      (amount === stockNumber ||
                        stockNumber === 0)) ||
                    (productHasLimitedStock &&
                      insideCart > 0 &&
                      isNumber(stockData) &&
                      insideCart + amount >= stockNumber)
                  }
                  onClick={() => setAmount(amount + 1)}
                  type="button"
                  aria-label={t("addQty")}
                >
                  <i className="Icon Icon--plus" />
                </button>
              </div>

              <button
                className="Product-addToCartButton"
                disabled={
                  !productIsPurchasable ||
                  !amount ||
                  addToCartStatus === "loading" ||
                  (productHasLimitedStock &&
                    (amount > stockNumber || stockNumber === 0)) ||
                  (productHasLimitedStock &&
                    insideCart > 0 &&
                    isNumber(stockData) &&
                    insideCart >= stockNumber)
                }
                onClick={() => {
                  setAddToCartStatus("loading");

                  addToCart().then(() => {
                    setAddToCartStatus("");
                  });
                }}
              >
                {addToCartLabel}
              </button>
            </div>
          ) : null}

          {(product.contact_only_text === "1" ||
            find(product.relatedProducts, {
              contact_only_text: "1",
            })) && (
            <div className="Product-additionalForm">
              <button
                className="Product-orderFromSupportButton"
                onClick={() => setContactCSPopupOpen(true)}
              >
                {t("orderFromCustomerService")}
              </button>
            </div>
          )}

          {showOutOfStockForm ? (
            <form
              className="Product-additionalForm"
              onSubmit={registerStockNotification}
            >
              <div className="Product-outOfStockText">
                {camelCase(product.delivery) === "comingSoon"
                  ? t("comingSoonText")
                  : t("outOfStockText")}
              </div>

              <label
                className={classNames("Product-outOfStockLabel", {
                  "is-submitting": outOfStockFormStatus === "submitting",
                })}
              >
                <input
                  className="Product-outOfStockInput"
                  disabled={
                    outOfStockFormStatus === "submitting" ||
                    outOfStockFormStatus === "success"
                  }
                  name="email"
                  onChange={(event) => setOutOfStockEmail(event.target.value)}
                  required
                  type="email"
                  value={outOfStockEmail}
                />

                {!outOfStockEmail && (
                  <span className="Product-outOfStockLabelText">
                    {t("outOfStockEmailLabel")}
                  </span>
                )}
              </label>

              <button
                className="Product-outOfStockButton"
                disabled={
                  outOfStockFormStatus === "submitting" ||
                  outOfStockFormStatus === "success"
                }
              >
                {t("outOfStockSubmit")}
              </button>

              {outOfStockFormStatus === "success" && (
                <div className="Product-outOfStockResponse">
                  {t("outOfStockThanks")}
                </div>
              )}

              {outOfStockFormStatus === "error" && (
                <div className="Product-outOfStockResponse">
                  {t("outOfStockError")}
                </div>
              )}
            </form>
          ) : null}

          {productIsPurchasable &&
          !(productHasLimitedStock && stockNumber === 0) &&
          klarnaIsAvailable ? (
            <klarna-placement
              data-key="credit-promotion-auto-size"
              data-locale={router.locale === "sv" ? "sv-SE" : "en-SE"}
              data-purchase-amount={product.priceAsNumber * 100}
            />
          ) : null}

          <div className="Product-tabsWrapper">
            <div
              className="Product-tabs"
              style={{ "--tab-count": tabCount } as React.CSSProperties}
            >
              {tabs
                .filter((t) => t.condition)
                .map((t, i) => (
                  <details
                    className="Product-tab"
                    open={i === activeTab}
                    key={t.label}
                    style={{ "--tab-index": i } as React.CSSProperties}
                  >
                    <summary
                      className="Product-tabSummary"
                      // Use an onClick event instead of onToggle to prevent
                      // a flash where two <details> are open at the same time
                      onClick={(event) => {
                        event.preventDefault();
                        setActiveTab(i);

                        const target = event.currentTarget as any;

                        if (isMobile) {
                          setTimeout(() => {
                            target.scrollIntoViewIfNeeded?.();
                          }, 0);
                        }
                      }}
                    >
                      <div>
                        <span>{t.label}</span>
                        <i className="Icon Icon--arrowRight" />
                      </div>
                    </summary>

                    <div className="Product-tabContent">
                      <div dangerouslySetInnerHTML={{ __html: t.content }} />
                    </div>
                  </details>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const canonicalLocalePrefix =
    router.locale !== router.defaultLocale ? "/" + router.locale : "";

  const ogImages =
    productMedia?.map((element) => {
      return { url: element };
    }) || false;
  let openGraph = {
    description: product.metaDescription,
    type: "website",
    title: product?.no_brand_hidden
      ? `${product.name} — ${last(product.categoryName)} | Artilleriet`
      : `${product.brandName} — ${product.name} — ${last(
          product.categoryName
        )} | Artilleriet`,
  };
  if (ogImages) {
    openGraph = Object.assign(openGraph, {
      images: ogImages,
    });
  }

  return (
    <>
      <NextSeo
        openGraph={openGraph}
        additionalLinkTags={[
          {
            rel: "canonical",
            href: category
              ? `https://artilleriet.se${canonicalLocalePrefix}/${category.uri}/${product.uri}`
              : "",
          },
        ]}
      />

      {klarnaIsAvailable ? (
        <Script
          src="https://eu-library.klarnaservices.com/lib.js"
          data-client-id="34b7a35b-0a08-5c25-898c-f089460e3e83"
        />
      ) : null}

      {breadcrumbs?.length ? (
        <div className="Breadcrumbs" ref={breadcrumbsRef}>
          <ul className="Breadcrumbs-content" ref={breadcrumbsContentRef}>
            {breadcrumbs.map((b, i) => (
              <React.Fragment key={b.full_slug}>
                {i > 0 ? <i className="Icon Icon--arrowRight" /> : null}
                <li className="Breadcrumbs-item Breadcrumbs-item--bold">
                  <Link href={"/" + b.full_slug}>{b.content.name}</Link>
                </li>
              </React.Fragment>
            ))}

            <i className="Icon Icon--arrowRight" />

            <li className="Breadcrumbs-item">
              <Link href={router.asPath}>{product.name}</Link>
            </li>
          </ul>

          <div className="Breadcrumbs-sortButton">
            <button onClick={() => setFaqPanelOpen(true)} type="button">
              {t("faqPanel")}
            </button>
          </div>
        </div>
      ) : null}

      {productNode}

      <div id="depict_pdp" />

      <CustomerServicePopup
        open={contactCSPopupOpen}
        setOpen={setContactCSPopupOpen}
        additionalData={{
          subject: tContactForm("subjects.purchasingAssistance"),
          productName: product.name,
          productId: product.product,
        }}
      />

      <FaqPanel open={faqPanelOpen} setOpen={setFaqPanelOpen} />
    </>
  );
}
