import classNames from "classnames";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import useSWR from "swr";

import { centraGet, centraGetProduct } from "../lib/centra-api";
import { useCentraToken, useRandomDoodle } from "../lib/utils";
import CentraProductImage from "./CentraProductImage";
import CentraProductLink from "./CentraProductLink";

export default function CartItem({ item, item_eur, disabled, setQuantity, currency }) {
  const router = useRouter();
  const [token] = useCentraToken();

  const isGiftCertificate = item.brandName === "Gift certificate";

  const doodle = useRandomDoodle();

  const t = useTranslations("Cart");
  const tProduct = useTranslations("ProductPage");

  const { data } = useSWR(
    `centraproduct://${item.product.product}/${router.locale}/${currency}`,
    (uri) => centraGetProduct(uri, token)
  );

  const { data: selectionData } = useSWR(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`,
    (uri) => centraGet(uri, token)
  );

  const product = data?.product;
  const stock = product?.items[0]?.stock; 
  const stockNumber = Number(stock);

  let deliveryLabel = "";
  let insideCart = 0;

  if (product) {
    insideCart =
      selectionData?.selection?.items?.find((p) => product.sku === p.sku)
        ?.quantity || 0;
  }

  // "Empty" means product is limited and will not be in stock again
  // and will not display out of stock notice form, "Sold Out" means
  // product is limited but might be in stock again, and should
  // hence show stock notice form
  const productHasLimitedStock =
    product?.delivery === "Empty" ||
    product?.delivery === "Sold Out" ||
    product?.delivery === ""; // legacy attribute
    
  if (stockNumber - insideCart >= 0) {
    deliveryLabel = tProduct("inStock");
  } else if (product?.delivery_status && !productHasLimitedStock) {
    deliveryLabel = `${product.delivery_status} (${product.delivery})`;
  } else if (productHasLimitedStock) {
    deliveryLabel = tProduct("noMoreInStock");
  }
  
  return (
    <li className="Cart-item">
      {!isGiftCertificate ? (
        <>
          {item.product.media?.portrait?.[0] ? (
            <div className="Cart-itemImageWrapper">
              <CentraProductLink product={item.product}>
                <CentraProductImage
                  className="Cart-itemImage"
                  src={item.product.media.portrait[0]}
                  alt={item.product.name}
                />
              </CentraProductLink>
            </div>
          ) : null}

          <div className="Cart-itemMeta">
            {item.product.no_brand_hidden !== "1" ? (
              <span className="Cart-itemBrand">{item.product.brandName}</span>
            ) : null}

            <div className="Cart-itemQuantity" data-id={item.item}>
              <button
                className="Cart-itemQuantityButton"
                disabled={disabled}
                onClick={() => setQuantity(item, item.quantity - 1)}
                type="button"
              >
                <i className="Icon Icon--minus" />
              </button>

              <span className="Cart-itemQuantityNumber">{item.quantity}</span>

              <button
                className="Cart-itemQuantityButton"
                disabled={
                  disabled || (productHasLimitedStock && insideCart >= stockNumber)
                }
                onClick={() => setQuantity(item, item.quantity + 1, stockNumber )}
                type="button"
              >
                <i className="Icon Icon--plus" />
              </button>
              <span className="Cart-itemNoMore">
                {tProduct("noMoreInStock")}
              </span>
            </div>

            <button
              className="Cart-itemRemove"
              disabled={disabled}
              onClick={() => setQuantity(item, 0)}
              type="button"
            >
              {t("removeItemButton")}
            </button>
          </div>

          <div className="Cart-itemBody">
            <div>
              <h5 className="Cart-itemTitle">
                <CentraProductLink product={item.product}>
                  {item.product.name}
                </CentraProductLink>
              </h5>

              <div className="Cart-itemAvailability">{deliveryLabel}</div>
            </div>

            <div
              className={classNames("Cart-itemPrice", {
                "Cart-itemPrice--discount": item.product.showAsOnSale,
              })}
            >
              {item.product.showAsOnSale ? (
                <span>
                  {item.totalPriceBeforeDiscount}
                  {doodle && <img src={doodle} alt="" />}
                </span>
              ) : null}
              {item.totalPrice}
              {item_eur && <span className="Cart-eur">({item_eur.totalPrice})</span>}
            </div>
          </div>
        </>
      ) : (
        // Gift cards don't have the same properties as
        // regular product, hence the conditional rendering
        <>
          <div className="Cart-itemImageWrapper">
            <img
              className="Cart-itemImage"
              src={"/images/gift-card.jpg"}
              alt={"Artilleriet Gift Card"}
            />
          </div>
          <div className="Cart-itemMeta">
            <span className="Cart-itemBrand">Artilleriet</span>

            <button
              className="Cart-itemRemove"
              disabled={disabled}
              onClick={() => setQuantity(item, 0)}
              type="button"
            >
              {t("removeItemButton")}
            </button>
          </div>
          <div className="Cart-itemBody">
            <div>
              <h5 className="Cart-itemTitle">{`${tProduct(
                "giftCard.giftCard"
              )} - ${item.totalPrice}`}</h5>
            </div>

            <div className={"Cart-itemPrice"}>
              {item.totalPrice}
              {item_eur && <span className="Cart-eur">({item_eur.totalPrice})</span>}
            </div>
          </div>
        </>
      )}
    </li>
  );
}
